import EventDispatcher from '../lib/event-dispatcher';

export default function Menu() {
    this.events = new EventDispatcher(this);
    this.els = {
        menu: document.getElementById('full-page-menu'),
        hamburger: document.getElementById('hamburger'),
    };
    this.shown = false;

    this.els.hamburger.addEventListener('click', event => {
        if (this.shown) {
            this.hide();
        } else {
            this.show();
        }

        event.preventDefault();
    });

    // Change hamburger state
    this.events.on('show', () => {
        this.els.hamburger.classList.add('is-active');
        this.els.hamburger.setAttribute('aria-label', 'Hide menu');
    });

    this.events.on('hide', () => {
        this.els.hamburger.classList.remove('is-active');
        this.els.hamburger.setAttribute('aria-label', 'Show menu');
    });
}

Menu.prototype.show = function () {
    this.shown = true;
    this.els.menu.removeAttribute('hidden');
    // this.els.menu.classList.add('is-shown');
    this.events.dispatch('show');
};

Menu.prototype.hide = function () {
    this.shown = false;
    this.els.menu.setAttribute('hidden', true);
    // this.els.menu.classList.remove('is-shown');
    this.events.dispatch('hide');
};
