import { createPrevButton, createNextButton } from './carousel-buttons';
import Swiper from 'swiper';

const updateUrl = true;

export default function ProductSlider(placeholder, keys, products, current) {
    this.initialSlide = keys.indexOf(current);
    this.keys = keys;
    this.products = products;
    this.container = createContainer(this.products);

    placeholder.parentNode.replaceChild(this.container, placeholder);

    this.swiper = createSwiper(this.container, this.initialSlide);
    this.swiper.on('slideChangeStart', this.handleSlideChange.bind(this));
    this.els = {
        header: document.getElementById('product-header'),
        title: document.getElementById('product-title'),
        cooking: document.getElementById('cooking'),
        ingredients: document.getElementById('ingredients'),
        nutrition: document.getElementById('nutrition'),
        servingSuggestion: document.getElementById('serving-suggestion'),
        buy: document.getElementById('buy'),
        retailers: document.getElementById('retailers-list'),
    };

    if (updateUrl) {
        const slideChangeHandler = this.updateHistory.bind(this);
        this.setInitialState();
        this.swiper.on('slideChangeStart', slideChangeHandler);

        window.addEventListener('popstate', event => {
            if (event.state === null) {
                return;
            }

            if (event.state.slide !== undefined) {
                this.swiper.off('slideChangeStart', slideChangeHandler);
                this.swiper.slideTo(event.state.slide);
                this.swiper.on('slideChangeStart', slideChangeHandler);
            }
        });
    }
}

ProductSlider.prototype.getProductById = function (id) {
    return this.products[this.keys.indexOf(id)];
};

ProductSlider.prototype.handleSlideChange = function () {
    const productId = parseInt(this.getCurrentSlide().getAttribute('data-product-id'), 10);
    const product = this.getProductById(productId);

    this.els.header.className = `product__header  ${product.headerClass}`;
    this.els.title.innerHTML = product.html.title;
    this.els.cooking.innerHTML = product.html.cooking;
    this.els.ingredients.innerHTML = product.html.ingredients;
    this.els.nutrition.innerHTML = product.html.nutrition;
    this.els.servingSuggestion.innerHTML = product.html.servingSuggestion;
    this.els.retailers.innerHTML = product.html.retailers;

    if (product.html.retailers) {
        this.els.buy.removeAttribute('hidden');
    } else {
        this.els.buy.setAttribute('hidden', '');
    }
};

ProductSlider.prototype.getCurrentSlide = function () {
    return this.swiper.slides[this.swiper.realIndex];
};

ProductSlider.prototype.setInitialState = function () {
    history.replaceState({ slide: this.initialSlide }, '', this.getCurrentSlide().getAttribute('data-url'));
};

ProductSlider.prototype.updateHistory = function () {
    history.pushState({ slide: this.swiper.realIndex }, '', this.getCurrentSlide().getAttribute('data-url'));
};

function createContainer(products) {
    const container = document.createElement('div');
    container.className = 'product__carousel  swiper-container';
    container.appendChild(createWrapper(products));
    container.appendChild(createPrevButton('product__arrow'));
    container.appendChild(createNextButton('product__arrow'));

    return container;
}

function createWrapper(products) {
    const wrapper = document.createElement('div');
    wrapper.className = 'swiper-wrapper';

    products.forEach(product => {
        wrapper.appendChild(createSlide(product));
    });

    return wrapper;
}

function createSwiper(container, initialSlide) {
    return new Swiper(container, {
        initialSlide: initialSlide,
        a11y: true,
        speed: 500,
        simulateTouch: false,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        prevButton: '.js-prev',
        nextButton: '.js-next',
        buttonDisabledClass: 'arrow--disabled',
        onInit: swiper => {
            Array.prototype.forEach.call(swiper.slides, slide => {
                slide.classList.add('product__slide--animated');
            });
        },
    });
}

function createSlide(product) {
    const slide = document.createElement('div');
    slide.className = 'swiper-slide  product__slide';
    slide.setAttribute('data-url', product.url);
    slide.setAttribute('data-product-id', product.id);
    slide.innerHTML = `
        <div class="flex-fix">
            <div class="product__lockup">
                <img src="${product.image}" alt="" class="product__img">
                ${product.html.badge}
            </div>
        </div>
    `;

    return slide;
}
