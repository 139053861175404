import dom from '../lib/dom';

export default class StarRating {
    constructor(el) {
        this.items = dom.get('label', el);
        this.icons = dom.get('use', el);
        this.inputs = dom.get('input[type=radio]', el);

        el.addEventListener('input', this.handleInput.bind(this), true);
        el.addEventListener('input', this.submit.bind(this), true);
        this.handleInput();

        this.items.forEach((item, i) => {
            item.addEventListener('mouseenter', this.update.bind(this, i));
            item.addEventListener('mouseleave', this.handleInput.bind(this));
        });
    }

    update(rating) {
        this.icons.forEach((icon, i) => {
            const selected = i <= rating;
            const symbol = selected ? '#star-fill' : '#star';

            icon.setAttributeNS('http://www.w3.org/1999/xlink', 'href', symbol);
        });
    }

    handleInput() {
        this.update(this.getRating());
    }

    submit(event) {
        event.target.form.submit();
    }

    getRating() {
        return this.inputs.findIndex(input => input.checked);
    }

    static initAll(selector = '.js-rating') {
        return dom.get(selector).map(el => {
            return new StarRating(el);
        });
    }
}
