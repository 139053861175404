import dom from '../../lib/dom';
import Swiper from 'swiper';
import SwiperUtil from '../swiper-util';

export default class TableOfContents {
    constructor(el) {
        this.el = SwiperUtil.createContainer(el, dom.get('.js-toc-item', el));
        this.swiper = new Swiper(this.el, {
            a11y: true,
            slidesPerView: 5,
            breakpoints: {
                1100: {
                    slidesPerView: 4,
                    spaceBetween: 8,
                },
                840: {
                    slidesPerView: 3,
                },
                640: {
                    slidesPerView: 2,
                },
                380: {
                    slidesPerView: 1,
                },
            },
        });
    }

    static initAll(selector = '.js-toc') {
        return dom.get(selector).map(el => {
            return new TableOfContents(el);
        });
    }
}
