import dom from './dom';
import EventDispatcher from './event-dispatcher';

function unhash(hash) {
    return hash.replace(/^#/, '');
}

export default function A11yTabs(container, selectedClass = 'is-selected', mq = null) {
    this.container = container;
    this.events = new EventDispatcher(this);
    this.selectedClass = selectedClass;
    this.mq = mq;
    this.target = null;

    this.tabs = dom.get('a', container).map(tab => {
        tab.addEventListener('click', () => {
            if (this.mqMatches()) {
                this.target = document.getElementById(unhash(tab.hash));
                this.target.id = '';

                if (window.location.hash === tab.hash) {
                    setTimeout(this.update.bind(this));
                }
            }
        });

        return tab;
    });

    this.targets = this.tabs.map(tab => tab.hash);

    this.panels = this.targets.map(target => {
        return document.getElementById(unhash(target));
    }).map(panel => {
        panel.dataset.old = panel.id;

        return panel;
    });

    if (this.mq) {
        this.mq.addListener(() => this.addRoles());
    }

    this.addRoles();

    window.addEventListener('hashchange', () => {
        if (this.mqMatches()) {
            this.update();
        }
    });

    if (this.mq) {
        this.mq.addListener(() => this.setInitialState());
    }

    this.setInitialState();
}

A11yTabs.prototype.setInitialState = function () {
    if (this.mqMatches()) {
        if (this.targets.indexOf(window.location.hash) === -1) {
            this.show();
        } else {
            this.update();
        }
    }
};

A11yTabs.prototype.addRoles = function () {
    if (this.mqMatches()) {
        this.container.setAttribute('role', 'tablist');
        this.tabs.forEach(tab => {
            tab.setAttribute('role', 'tab');
            tab.setAttribute('aria-controls', unhash(tab.hash));
        });
        this.panels.forEach(panel => {
            panel.setAttribute('role', 'tabpanel');
        });
    } else {
        this.container.removeAttribute('role');
        this.tabs.forEach(tab => {
            tab.removeAttribute('role');
            tab.removeAttribute('aria-controls');
            tab.removeAttribute('aria-selected');
        });
        this.panels.forEach(panel => {
            panel.removeAttribute('role');
            panel.removeAttribute('aria-hidden');
        });
    }
};

A11yTabs.prototype.mqMatches = function () {
    return this.mq === null || this.mq.matches;
};

A11yTabs.prototype.update = function () {
    if (this.target) {
        this.target.id = this.target.dataset.old;
        this.target = null;
    }

    this.events.dispatch('update');

    const hash = window.location.hash;

    if (this.targets.indexOf(hash) !== -1) {
        return this.show(hash);
    }

    if (!hash) {
        this.show();
    }
};

A11yTabs.prototype.show = function (id) {
    if (!id) {
        id = this.targets[0];
    }

    this.tabs.forEach(tab => {
        const match = tab.hash === id;
        tab.classList[match ? 'add' : 'remove'](this.selectedClass);
        tab.setAttribute('aria-selected', match);
    });

    this.panels.forEach(panel => {
        panel.setAttribute('aria-hidden', `#${panel.id}` !== id);
    });

    this.events.dispatch('show', id);
};
