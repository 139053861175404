import EventDispatcher from '../lib/event-dispatcher';
import reqwest from 'reqwest';
import util from '../lib/util';

const defaultConfig = {
    inputDelay: 500,
    minimumLength: 3,
    classes: {
        empty: 'is-empty',
        hidden: 'is-hidden',
        shown: 'is-shown',
    },
};

export default function Search(dialog, url, config = {}) {
    this.dialog = dialog;
    this.url = url;
    this.config = util.extend({}, defaultConfig, config);
    this.events = new EventDispatcher(this);
    this.els = {
        form: document.getElementById('search'),
        input: document.getElementById('search-input'),
        tools: document.getElementById('search-tools'),
        results: document.getElementById('search-results'),
        noResults: document.getElementById('search-no-results'),
        loading: document.getElementById('search-loading'),
    };
    this.currentRequest = null;
    this.inputTimeout = null;

    // Throttle search field input to fire relevant events after the user
    // pauses typing.
    this.els.input.addEventListener('input', () => {
        if (this.inputTimeout) {
            window.clearTimeout(this.inputTimeout);
        }

        this.inputTimeout = window.setTimeout(this.handleInput.bind(this), this.config.inputDelay);
    });

    // Perform the search
    this.events.on('input', value => {
        this.els.results.innerHTML = '';
        this.els.noResults.classList.add(this.config.classes.hidden);
        this.els.form.classList.remove(this.config.classes.empty);
        this.els.tools.classList.add(this.config.classes.hidden);
        this.currentRequest = this.search(value);
    });

    // Show loading icon
    this.events.on('search', () => {
        this.els.loading.classList.add(this.config.classes.shown);
    });

    // Populate results
    this.events.on('results', (query, results) => {
        this.els.results.classList.remove(this.config.classes.empty);
        this.els.loading.classList.remove(this.config.classes.shown);
        this.els.results.innerHTML = results;

        if (results.length === 0) {
            this.els.noResults.classList.remove(this.config.classes.hidden);
        }

        this.events.dispatch('results_loaded');
    });

    // Reset the search form
    this.events.on('empty', () => {
        if (this.currentRequest) {
            this.currentRequest.abort();
            this.currentRequest = null;
        }

        this.reset();
    });

    this.dialog.on('show', () => {
        this.events.dispatch('show');
        this.els.input.focus();
    });

    this.dialog.on('hide', () => {
        this.events.dispatch('hide');
        this.reset();
    });
}

Search.prototype.search = function (query, postTypes = ['recipe', 'product']) {
    this.events.dispatch('search', query);

    return reqwest({
        url: this.url,
        type: 'html',
        method: 'get',
        data: {
            action: 'search',
            query: query,
            post_types: postTypes,
        },
        success: response => {
            // Bug in Reqwest library means empty responses don’t just get
            // passed as empty strings. Instead we receive the XMLHttpRequest
            // object.
            if (response instanceof XMLHttpRequest) {
                response = response.responseText || '';
            }

            this.events.dispatch('results', query, response);
        },
    });
};

Search.prototype.handleInput = function () {
    if (this.els.input.value.length >= this.config.minimumLength) {
        this.events.dispatch('input', this.els.input.value);
    }

    if (this.els.input.value.length === 0) {
        this.events.dispatch('empty');
    }
};

Search.prototype.reset = function () {
    this.els.input.value = '';
    this.els.form.classList.add(this.config.classes.empty);
    this.els.tools.classList.remove(this.config.classes.hidden);
    this.els.results.classList.add(this.config.classes.empty);
    this.els.results.innerHTML = '';
    this.els.noResults.classList.add(this.config.classes.hidden);
};
