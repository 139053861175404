import dom from '../lib/dom';

export default class FileInput {
    constructor(input) {
        this.input = input;
        this.input.addEventListener('change', this.handleInput.bind(this));
        this.filename = document.getElementById(this.input.getAttribute('data-target'));

        if (null === this.filename) {
            throw new Error('Unable to find target element.');
        }
    }

    handleInput() {
        this.filename.innerText = FileInput.basename(this.input.value);
    }

    static basename(path) {
        return path.split(/(\\|\/)/).pop();
    }

    static initAll(selector = '.js-file-input') {
        return dom.get(selector).map(input => {
            return new FileInput(input);
        });
    }
}
