export default function EventDispatcher(thisArg = null) {
    this.events = {};
    this.thisArg = thisArg;
}

EventDispatcher.prototype.on = function (event, fn) {
    if (typeof this.events[event] === 'undefined') {
        this.events[event] = [];
    }

    this.events[event].push(fn);

    return this;
};

EventDispatcher.prototype.off = function (event, fn) {
    if (typeof this.events[event] === 'undefined') {
        return this;
    }

    const index = this.events[event].indexOf(fn);

    if (index !== -1) {
        this.events[event].splice(index, 1);
    }

    return this;
};

EventDispatcher.prototype.dispatch = function (event, ...data) {
    if (typeof this.events[event] === 'undefined') {
        return this;
    }

    this.events[event].forEach(fn => {
        fn.apply(this.thisArg, data);
    });

    return this;
};
