export function createPrevButton(bemPrefix) {
    const button = createButton();
    button.classList.add(bemPrefix);
    button.classList.add(`${bemPrefix}--prev`);
    button.classList.add('js-prev');

    return button;
}

export function createNextButton(bemPrefix) {
    const button = createButton();
    button.classList.add(bemPrefix);
    button.classList.add(`${bemPrefix}--next`);
    button.classList.add('js-next');

    return button;
}

export function createButton() {
    const button = document.createElement('button');
    button.type = 'button';
    button.className = 'arrow';
    button.innerHTML = `
        <svg class="icon  icon--block  icon--arrow  arrow__icon">
            <use xlink:href="${window.svgSpriteUrl}#arrow"></use>
        </svg>
    `;

    return button;
}
