import dom from '../lib/dom';

export default class WordCount {
    constructor(el) {
        this.el = el;
        this.maxWords = parseInt(el.getAttribute('data-max-words'), 10);
        this.label = WordCount.getLabel(el.getAttribute('data-label'));
        this.target = document.getElementById(el.getAttribute('data-target'));

        if (null === this.target) {
            throw new Error('Word count target element does not exist.');
        }

        this.target.addEventListener('input', this.handleInput.bind(this));
        this.handleInput();
    }

    handleInput() {
        this.target.value = WordCount.truncate(this.target.value, this.maxWords);
        this.updateLabel(this.maxWords - WordCount.countWords(this.target.value));
    }

    updateLabel(count) {
        let label = this.label[count === 1 ? 'one' : 'multiple'];
        label = label.replace('%d', count);

        this.el.innerText = label;
    }

    static countWords(text) {
        return WordCount.toWords(text).filter(word => word.length > 0).length;
    }

    static truncate(text, wordCount) {
        return WordCount.toWords(text).slice(0, wordCount).join(' ');
    }

    static toWords(text) {
        return text.split(/\s+(?!$)/);
    }

    static getLabel(label) {
        let multiple;
        let one;

        label = label.split('|');
        multiple = label[0];

        if (label.length === 1) {
            one = label[0];
        } else {
            one = label[1];
        }

        return { multiple, one };
    }

    static initAll(selector = '.js-word-count') {
        return dom.get(selector).map(el => {
            return new WordCount(el);
        });
    }
}
