import dom from '../../lib/dom';
import Swiper from 'swiper';
import SwiperUtil from '../swiper-util';

export default class RecipeSlider {
    constructor(container) {
        if (null === container) {
            return;
        }

        this.container = SwiperUtil.createContainer(container, dom.get('.js-recipe-slide', container));
        this.swiper = RecipeSlider.createSwiper(this.container);
        this.retailersContainer = document.getElementById('retailers-list');

        dom.get('a.js-buy-button', this.container).forEach(link => {
            const button = document.createElement('button');
            button.setAttribute('data-buy-now', link.pathname);
            button.className = link.className;
            button.innerText = link.innerText;

            link.parentNode.replaceChild(button, link);
        });
    }

    static createSwiper(container) {
        return new Swiper(container, {
            a11y: true,
            simulateTouch: false,
            onInit: () => SwiperUtil.showNav(container),
            prevButton: '.js-prev-button',
            nextButton: '.js-next-button',
            buttonDisabledClass: 'is-disabled',
        });
    }
}
