'use strict';

export default function CutsTheMustard(testExpr = undefined) {
    if (testExpr === undefined) {
        testExpr = 'classList' in document.documentElement;
    } else if (typeof testExpr === 'function') {
        testExpr = testExpr.call();
    }

    this.passes = !!testExpr;
}

CutsTheMustard.prototype.pass = function (cb) {
    if (this.passes) {
        cb.call();
    }

    return this;
};

CutsTheMustard.prototype.fail = function (cb) {
    if (!this.passes) {
        cb.call();
    }

    return this;
};

CutsTheMustard.init = function (testExpr) {
    return new CutsTheMustard(testExpr);
};
