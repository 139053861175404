export default class QuizScore {
    constructor(questionCount) {
        this.questionCount = questionCount;
        this.score = document.getElementById('quiz-score');
        this.heading = document.getElementById('quiz-result-heading');
        this.text = document.getElementById('quiz-result-text');
    }

    setScore(score) {
        if (null === this.score) {
            return;
        }

        this.score.innerText = this.getPercentage(score);
        this.heading.innerText = this.getHeading(score);
        this.text.innerText = this.getText(score);
    }

    getHeading(score) {
        switch (score) {
            case 0:
                return 'Oh dear!';
            default:
                return 'Congratulations!';
        }
    }

    getText(score) {
        switch (score) {
            case 0:
                // return 'I think you would benefit rather from reading my discerning guide.';
                return 'I think we need a little more time at finishing school, darling.';
            case 1:
                return 'I think we need a little more time at finishing school, darling.';
            case 2:
                return 'I say, it’s a good start, but you may need some polishing.';
            case 3:
                return 'Hmm, more Gastro than not, superb.';
            case 4:
                return 'Ooh, you are rather discerning, aren’t you my dear.';
            case 5:
                return 'Well, you are our kind of sophisticated diner. Bravo!';
        }
    }

    getPercentage(score) {
        return (score / this.questionCount) * 100;
    }
}
