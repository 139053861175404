import event from '../lib/event';
import util from '../lib/util';

export default function Messenger(namespace, eventTarget = window) {
    this.namespace = namespace;
    this.eventTarget = eventTarget;
    this.listeners = {};
    this.defaultParams = {};
    this.debug = false;
}

Messenger.prototype.prepareData = function (data) {
    return window.JSON.stringify(util.shallowExtend({}, this.defaultParams, data));
};

Messenger.prototype.addDefaultParam = function (key, value) {
    this.defaultParams[key] = value;
};

Messenger.prototype.send = function (target, data) {
    try {
        target = target.contentWindow;
    } catch (e) {}

    target.postMessage(this.prepareData(data), '*');
};

Messenger.prototype.listen = function (expectedOrigin) {
    let listener = this.listeners[expectedOrigin] = (message) => {
        let origin = message.origin || message.originalEvent.origin;
        let data;

        if (origin !== expectedOrigin) {
            this.log(`Expected origin ${expectedOrigin}; actual origin ${origin}`);
            return;
        }

        if (!message.data) {
            return;
        }

        try {
            data = window.JSON.parse(message.data);
        } catch (e) {
            return;
        }

        event.dispatch(this.eventTarget, `${this.namespace}:${data.event}`, { detail: data });

        this.log(`Dispatched event ${this.namespace}:${data.event}`);
    };

    window.addEventListener('message', listener);
};

Messenger.prototype.unlisten = function (expectedOrigin) {
    if (this.listeners[expectedOrigin] === undefined) {
        return;
    }

    window.removeEventListener('message', this.listeners[expectedOrigin]);
};

Messenger.prototype.log = function (message) {
    if (this.debug) {
        console.log(message);
    }
};
