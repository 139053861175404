import dom from '../../lib/dom';
import EventDispatcher from '../../lib/event-dispatcher';
import QuizScore from './quiz-score';
import Swiper from 'swiper';
import SwiperUtil from '../swiper-util';

export default class Quiz {
    constructor(container) {
        if (null === container) {
            return;
        }

        this.showResultSlide();
        this.events = new EventDispatcher(this);
        this.container = SwiperUtil.createContainer(container, dom.get('.js-quiz-slide', container));
        this.swiper = Quiz.createSwiper(this.container);
        this.form = document.getElementById('quiz-form');
        this.resultHeading = document.getElementById('quiz-result-heading');
        this.answers = JSON.parse(this.container.getAttribute('data-correct-answers'));
        this.score = new QuizScore(Object.keys(this.answers).length);
        this.reset();

        this.container.addEventListener('click', event => {
            if (event.target.hasAttribute('data-quiz-proceed')) {
                this.swiper.slideNext();
                event.preventDefault();
            }
        });

        dom.get('.js-quiz-option', this.container).forEach(option => {
            option.addEventListener('click', () => {
                const question = parseInt(option.getAttribute('data-question'), 10);
                const answer = parseInt(option.getAttribute('data-answer'), 10);

                this.handleAnswer(question, answer);
            });
        });

        this.events.on('answer', (quiz, question, answer) => {
            this.revealAnswer(question, answer);
        });

        this.events.on('answer', (quiz, question) => {
            this.disableOptions(question);
        });

        this.events.on('answer', () => {
            setTimeout(() => this.swiper.slideNext(), 1500);
        });

        this.events.on('answer:correct', () => {
            this.score.setScore(this.getScore());
        });
    }

    reset() {
        this.answered = [];
        this.correctAnswers = [];
        this.score.setScore(this.getScore());
        this.form.reset();

        if (this.swiper.activeIndex > 0) {
            this.swiper.slideTo(0);
        }

        this.events.dispatch('init');
    }

    handleAnswer(question, answer) {
        if (this.hasAnswered(question)) {
            return;
        }

        this.answered.push(question);
        this.events.dispatch('answer', this, question, answer);

        if (this.isCorrect(question, answer)) {
            this.correctAnswers.push(question);
            this.events.dispatch('answer:correct', this, question, answer);
        } else {
            this.events.dispatch('answer:incorrect', this, question, answer);
        }
    }

    hasAnswered(question) {
        return this.answered.indexOf(question) !== -1;
    }

    isCorrect(question, answer) {
        if (this.answers[question] === undefined) {
            return false;
        }

        if (this.answers[question][answer] === undefined) {
            return false;
        }

        return this.answers[question][answer];
    }

    getScore() {
        return this.correctAnswers.length;
    }

    disableOptions(question) {
        this.getOptions(question).forEach(option => option.classList.add('is-disabled'));
    }

    revealAnswer(question, answer) {
        const options = this.getOptions(question);

        if (this.isCorrect(question, answer)) {
            options[answer].appendChild(Quiz.createTick());
        } else {
            options[answer].appendChild(Quiz.createCross());

            this.answers[question].forEach((answer, i) => {
                if (answer) {
                    options[i].appendChild(Quiz.createTick());
                }
            });
        }
    }

    getOptions(question) {
        return dom.get('.js-quiz-option', this.getQuestionSlide(question));
    }

    getQuestionSlide(question) {
        return dom.get(`[data-hash='question-${question}']`, this.container)[0];
    }

    showResultSlide() {
        const result = document.getElementById('quiz-result');

        if (null === result) {
            return;
        }

        result.removeAttribute('hidden');
    }

    static createSwiper(container) {
        return new Swiper(container, {
            // slidesPerView: 2,
            a11y: true,
            onlyExternal: true,
            simulateTouch: false,
            onInit: swiper => {
                swiper.container[0].classList.add('is-init');
            },
        });
    }

    static createTick() {
        return Quiz.createIcon('tick');
    }

    static createCross() {
        return Quiz.createIcon('cross');
    }

    static createIcon(id) {
        const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
        use.setAttributeNS('http://www.w3.org/1999/xlink', 'href', `${window.gdnIconsUrl}#${id}`);

        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.classList.add('icon');
        svg.classList.add('GDN-option__icon');
        svg.appendChild(use);

        return svg;
    }
}
