export default function measureScrollbar(className = 'scrollbar-measure') {
    insertStyleSheet(className);

    const div = document.createElement('div');
    div.className = className;

    document.body.appendChild(div);
    const scrollbarWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);

    return scrollbarWidth;
}

function insertStyleSheet(className) {
    const style = document.createElement('style');
    style.innerText = `
        .${className} {
            position: absolute;
            left: -999em;
            width: 100px;
            height: 100px;
            overflow: scroll;
        }
    `;

    document.getElementsByTagName('script')[0].parentNode.appendChild(style);

    return style;
}
