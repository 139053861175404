import dom from '../../lib/dom';

export default class Floodlights {
    constructor(simulated = false) {
        this.simulated = simulated;

        this.send('DC-8939132/young0/young0+standard');

        setTimeout(this.send.bind(this, 'DC-8939132/young0/young00+standard'), 10000);
        setTimeout(this.send.bind(this, 'DC-8939132/young0/young000+standard'), 30000);
        setTimeout(this.send.bind(this, 'DC-8939132/young0/young001+standard'), 60000);

        dom.get('a.js-youngsters-recipe').forEach(link => {
            this.onLinkClick(link, 'DC-8939132/young0/young002+standard');
        });

        dom.get('a.js-youngsters-retailer').forEach(link => {
            this.onLinkClick(link, 'DC-8939132/young0/young003+standard');
        });

        dom.get('a.js-youngsters-explore').forEach(link => {
            this.onLinkClick(link, 'DC-8939132/young0/young004+standard');
        });
    }

    onLinkClick(link, trackingCode) {
        link.addEventListener('click', event => {
            if (!this.isInitialized()) {
                return;
            }

            if (link.target === '_blank') {
                this.send(trackingCode);
            } else {
                this.send(trackingCode, () => {
                    window.location = link.href;
                });

                event.preventDefault();
            }
        });
    }

    send(trackingCode, callback) {
        if (!this.isYoungsters() || !this.isInitialized()) {
            return;
        }

        if (this.simulated) {
            console.log(`Tracking event ${trackingCode}!`);

            if (callback) {
                console.log('Callback executing in 5s.');
                setTimeout(callback, 5000);
            }

            return;
        }

        window.gtag('event', 'conversion', {
            allow_custom_scripts: true,
            send_to: trackingCode,
            event_callback: callback,
        });
    }

    isInitialized() {
        if (this.simulated) {
            return true;
        }

        return typeof window.gtag !== 'undefined';
    }

    isYoungsters() {
        return document.body.classList.contains('body--page-template-youngsters');
    }
}
