import dom from './dom';
import EventDispatcher from './event-dispatcher';
import util from './util';

export default function HeightEqualiser(container, waitForLoad = true) {
    this.events = new EventDispatcher(this);
    this.container = container;
    this.grouped = this.container.hasAttribute('data-grouped');
    this.updateItems();

    window.addEventListener('resize', util.debounce(this.equalise.bind(this), 100));

    if (waitForLoad) {
        window.addEventListener('load', this.equalise.bind(this));
    } else {
        this.equalise();
    }
}

HeightEqualiser.prototype.equalise = function () {
    if (this.items.length === 0) {
        return;
    }

    this.reset();

    let groups;

    if (this.grouped) {
        groups = util.groupByYAxis(this.items);
    } else {
        groups = { items: this.items };
    }

    Object.keys(groups).forEach(y => {
        const group = groups[y];

        // No need to equalise heights if only one item sits at this
        // position.
        if (group.length === 1) {
            return;
        }

        const height = `${util.getMaxHeight(group)}px`;
        group.forEach(el => el.style.height = height);
    });

    this.events.dispatch('resize');
};

HeightEqualiser.prototype.reset = function () {
    this.items.forEach(item => item.style.height = '');
};

HeightEqualiser.prototype.updateItems = function () {
    this.items = dom.get('.js-equalise-me', this.container);
};
