'use strict';

export default (() => {

    /**
     * Converts an array-like object to an array.
     *
     * @param {mixed} el
     * @return {Array}
     */
    let toArray = function (el) {
        return Array.prototype.slice.call(el);
    };

    /**
     * Returns a function, that, as long as it continues to be invoked, will not
     * be triggered. The function will be called after it stops being called for
     * N milliseconds. If `immediate` is passed, trigger the function on the
     * leading edge, instead of the trailing.
     *
     * @param {Function} fn
     * @param {number} wait
     * @param {boolean} immediate
     * @return {Function}
     */
    let debounce = function (fn, wait, immediate = false) {
        let timeout;

    	return function () {
            let args = arguments;
    		let later = () => {
    			timeout = null;

    			if (!immediate) {
                    fn.apply(this, args);
                }
    		};
    		let callNow = immediate && !timeout;

    		clearTimeout(timeout);
    		timeout = setTimeout(later, wait);

    		if (callNow) {
                fn.apply(this, args);
            }
    	};
    };

    /**
     * Merges one or more objects together à la `jQuery.extend()`. Performs a
     * deep merge.
     *
     * @param {Object} out
     * @return {Object}
     */
    let extend = function (out = {}) {
        for (let i = 1; i < arguments.length; i++) {
            let obj = arguments[i];

            if (!obj) {
                continue;
            }

            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (typeof obj[key] === 'object') {
                        out[key] = extend(out[key], obj[key]);
                    } else {
                        out[key] = obj[key];
                    }
                }
            }
        }

        return out;
    };

    /**
     * Merges one or more objects together à la `jQuery.extend()`. Performs a
     * shallow (non-recursive) merge.
     *
     * @param {Object} out
     * @return {Object}
     */
    let shallowExtend = function (out = {}) {
        for (let i = 1; i < arguments.length; i++) {
            if (!arguments[i]) {
                continue;
            }

            for (let key in arguments[i]) {
                if (arguments[i].hasOwnProperty(key)) {
                    out[key] = arguments[i][key];
                }
            }
        }

        return out;
    };

    /**
     * Returns the maximum height of the supplied nodes.
     *
     * @param {Node[]} els
     * @return {number}
     */
    let getMaxHeight = function (els) {
        return Math.max.apply(null, els.map(el => el.offsetHeight));
    };

    /**
     * Equalises the heights of the elements
     *
     * @param {Node[]} els
     * @param {MediaQueryList} mq
     */
    let equaliseHeights = function (els, mq = null) {
        function matches() {
            return mq === null || mq.matches;
        }

        function reset() {
            els.forEach(el => el.style.height = '');
        }

        function equalise() {
            reset();

            if (!matches()) {
                return;
            }

            let height = getMaxHeight(els);
            els.forEach(el => el.style.height = `${height}px`);
        }

        window.addEventListener('load', equalise);
        window.addEventListener('resize', debounce(equalise, 50));
    };

    /**
     * Finds whether an element is visible in the current viewport.
     *
     * @param {Node} el
     * @return {Boolean}
     */
    let isInViewport = function (el) {
        let rect = el.getBoundingClientRect();
        let html = document.documentElement;

        return rect.top >= 0 &&
            rect.top < (window.innerHeight || html.clientHeight) &&
            rect.left >= 0 &&
            rect.left < (window.innerWidth || html.clientWidth)
        ;
    };

    /**
     * Randomises the order of elements in an array.
     *
     * @param {Array} array
     * @see https://www.frankmitchell.org/2015/01/fisher-yates/
     */
    let shuffle = function (array) {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            let temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    };

    /**
     * Returns elements grouped by their y-axis (distance from the top of the
     * page).
     */
    let groupByYAxis = function (els) {
        const groups = {};

        els.forEach(el => {
            const rect = el.getBoundingClientRect();
            const y = rect.top.toString();

            if (!(y in groups)) {
                groups[y] = [];
            }

            groups[y].push(el);
        });

        return groups;
    };

    return {
        debounce,
        equaliseHeights,
        extend,
        getMaxHeight,
        groupByYAxis,
        isInViewport,
        shallowExtend,
        shuffle,
        toArray,
    };

})();
