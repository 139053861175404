'use strict';

import util from './util';

export default (() => {

    /**
     * Creates a custom event
     *
     * @param {string} eventName
     * @param {Object} data
     */
    let create = (eventName, options = {}) => {
        let event;
        let eventOptions = util.shallowExtend({
            bubbles: true,
            cancelable: true,
            detail: {}
        }, options);

        if (typeof window.CustomEvent === 'function') {
            event = new window.CustomEvent(eventName, eventOptions);
        } else {
            event = window.document.createEvent('CustomEvent');
            event.initCustomEvent(eventName, eventOptions.bubbles, eventOptions.cancelable, eventOptions.detail);
        }

        return event;
    };

    /**
     * Dispatches a custom event
     *
     * @param {EventTarget} target
     * @param {string} eventName
     * @param {Object} data
     */
    let dispatch = (target, eventName, options = {}) => {
        return target.dispatchEvent(create(eventName, options));
    };

    return {
        dispatch: dispatch
    };

})();
