import dom from '../../lib/dom';
import Swiper from 'swiper';
import SwiperUtil from '../swiper-util';

export default class Slider {
    constructor(container) {
        this.container = SwiperUtil.createContainer(container, dom.get('.js-youngsters-slide', container));
        this.swiper = null;

        this.mq = window.matchMedia('(max-width: 1099px)');
        this.mq.addListener(this.handleMediaQuery.bind(this));
        this.handleMediaQuery();
    }

    handleMediaQuery() {
        if (this.mq.matches) {
            this.init();
        } else {
            this.destroy();
        }
    }

    init() {
        this.swiper = new Swiper(this.container, this.getSwiperConfig());
    }

    getSwiperConfig() {
        const config = {
            a11y: true,
            // freeMode: true,
            slidesPerView: 3,
            breakpoints: {
                799: {
                    slidesPerView: 2,
                },
                519: {
                    slidesPerView: 1,
                },
            },
            onInit: () => {
                this.container.classList.add('is-swiper');
            },
            onDestroy: () => {
                this.container.classList.remove('is-swiper');
            },
        };

        if (this.container.hasAttribute('data-prev-button')) {
            config.prevButton = document.getElementById(this.container.getAttribute('data-prev-button'));
        }

        if (this.container.hasAttribute('data-next-button')) {
            config.nextButton = document.getElementById(this.container.getAttribute('data-next-button'));
        }

        return config;
    }

    destroy() {
        if (this.swiper === null) {
            return;
        }

        this.swiper.destroy(true, true);
        this.swiper = null;
    }

    static initAll() {
        return dom.get('.js-youngsters-slider').map(el => {
            return new Slider(el);
        });
    }
}
