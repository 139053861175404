import dom from '../../lib/dom';
import Swiper from 'swiper';
import SwiperUtil from '../swiper-util';

export default class Benefits {
    constructor(el) {
        this.el = SwiperUtil.createContainer(el, dom.get('.js-benefits-item', el));
        this.swiper = new Swiper(this.el, {
            a11y: true,
            slidesPerView: 3,
            breakpoints: {
                700: {
                    slidesPerView: 2,
                },
                600: {
                    slidesPerView: 1,
                },
            },
        });
    }

    static initAll(selector = '.js-benefits') {
        return dom.get(selector).map(el => {
            return new Benefits(el);
        });
    }
}
