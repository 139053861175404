'use strict';

export default (() => {

    /**
     * Fetches elements which match the CSS selector, optionally filtered by
     * context (default is to search everywhere in the document).
     *
     * @param {string} selector
     * @param {Node} context
     * @return {Node[]}
     */
    let get = (selector, context = document) => {
        if (typeof context === 'string') {
            context = get(context)[0];
        }

        return Array.prototype.slice.call(context.querySelectorAll(selector));
    };

    /**
     * Returns whether the element matches the CSS selector.
     *
     * @param {Element} el
     * @param {string} selector
     * @return {boolean}
     */
    let is = (el, selector) => {
        let p = Element.prototype;
    	let fn = p.matches || p.webkitMatchesSelector || p.mozMatchesSelector || p.msMatchesSelector || (selector => {
            return get(selector).indexOf(this) !== -1;
    	});

    	return fn.call(el, selector);
    };

    /**
     * Creates an HTML element.
     *
     * @param {string} tag
     * @param {Object} properties
     * @return {Element}
     */
    let create = (tag, properties = {}) => {
        let el = document.createElement(tag);

        Object.keys(properties).forEach(property => {
            el[property] = properties[property];
        });

        return el;
    };

    /**
     * Sets HTML attributes on an element in bulk.
     *
     * @param {Element} el
     * @param {Object} attr
     * @return {Element}
     */
    let attr = (el, attr) => {
        Object.keys(attr).forEach(name => {
            el.setAttribute(name, attr[name]);
        });

        return el;
    };

    /**
     * Returns the closest parent element matching the selector, optionally
     * including the element itself in the search.
     *
     * @param {string} el
     * @param {string} selector
     * @param {boolean} includeSelf
     * @return {Element}
     */
    let closest = (el, selector, includeSelf = false) => {
        // The native `Element.closest()` method is slowly gaining browser
        // support. We’ll just use the native method if it’s available. It’s
        // implemented slightly differently in that it doesn’t make includeSelf
        // optional, so we need two variations of the call at our disposal to
        // accommodate.
        if (el.closest) {
            if (includeSelf) {
                return el.closest(selector);
            }

            return el.parentNode.closest(selector);
        }

        if (includeSelf && is(el, selector)) {
            return el;
        }

        let parent = el.parentNode;

        while (parent && parent !== document.body) {
            if (is(parent, selector)) {
                return parent;
            } else if (parent.parentNode) {
                parent = parent.parentNode;
            } else {
                return null;
            }
        }

        return null;
    };

    /**
     * Returns the siblings of the element, optionally filtered by a CSS
     * selector.
     *
     * @param {Element} el
     * @param {string} selector
     * @return {Element[]}
     */
    let siblings = (el, selector = undefined) => {
        if (!el.parentNode) {
            return [];
        }

        let siblings = Array.prototype.filter.call(el.parentNode.children, child => {
            return child !== el;
        });

        if (selector === undefined) {
            return siblings;
        }

        return siblings.filter(sibling => {
            return is(sibling, selector);
        });
    };

    /**
     * Returns the direct children of the element, optionally filtered by a CSS
     * selector.
     *
     * @param {Element} el
     * @param {string} selector
     * @return {Element[]}
     */
    let children = (el, selector = undefined) => {
        let children = Array.prototype.slice.call(el.children);

        if (selector === undefined) {
            return children;
        }

        return children.filter(child => is(child, selector));
    };

    /**
     * Finds focusable descendents of the parent element.
     *
     * Concept courtesy of https://github.com/edenspiekermann/a11y-dialog/blob/2.3.1/a11y-dialog.js#L29
     *
     * @param {Node} parent
     * @return {Node[]}
     */
    let getFocusable = (parent) => {
        let focusableElements = [
            'a[href]', 'area[href]', 'input:not([disabled])', 'select:not([disabled])',
            'textarea:not([disabled])', 'button:not([disabled])', 'iframe', 'object',
            'embed', '[contenteditable]', '[tabindex]:not([tabindex^="-"])'
        ];

        return get(focusableElements.join(','), parent).filter(child => {
            return !!(child.offsetWidth || child.offsetHeight || child.getClientRects().length);
        });
    };

    return {
        get: get,
        is: is,
        create: create,
        attr: attr,
        closest: closest,
        siblings: siblings,
        children: children,
        getFocusable: getFocusable
    };

})();
