import dom from '../lib/dom';

export default class SwiperUtil {
    static createContainer(container, slides) {
        const wrapper = SwiperUtil.createWrapper();

        slides.map(SwiperUtil.createSlide).forEach(slide => {
            wrapper.appendChild(slide);
        });

        container.classList.add('swiper-container');
        container.appendChild(wrapper);

        return container;
    }

    static createWrapper() {
        const wrapper = document.createElement('div');
        wrapper.className = 'swiper-wrapper';

        return wrapper;
    }

    static createSlide(el) {
        el.classList.add('swiper-slide');

        if (el.id) {
            el.setAttribute('data-hash', el.id);
            el.removeAttribute('id');
        }

        return el;
    }

    static showNav(container) {
        dom.get('.js-prev-button, .js-next-button', container).forEach(button => {
            button.removeAttribute('hidden');
        });
    }
}
