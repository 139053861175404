import dom from '../../lib/dom';

export default class CountUp {
    constructor(container, duration = 1000) {
        this.duration = duration;
        this.revealed = false;
        this.els = dom.get('.js-figure', container);
        this.init();
        this.waypoint = new Waypoint({
            element: container,
            offset: '75%',
            handler: this.reveal.bind(this),
        });
    }

    init() {
        this.els.forEach(el => {
            el.setAttribute('data-value', el.textContent);
            el.textContent = 0;
        });
    }

    reveal() {
        if (this.revealed) {
            return;
        }

        this.revealed = true;
        this.els.forEach(el => {
            this.count(el, parseInt(el.getAttribute('data-value'), 10));
        });
    }

    count(el, value) {
        const currentValue = parseInt(el.textContent, 10);
        const stepLength = this.duration / (value - currentValue);

        for (let i = 1; i <= value; i++) {
            setTimeout(this.getUpdater(el, i), i * stepLength);
        }
    }

    getUpdater(el, value) {
        return () => el.textContent = value;
    }

    static initAll(selector = '.js-figures') {
        return dom.get(selector).map(el => {
            return new CountUp(el);
        });
    }
}
