import dom from '../lib/dom';
import Swiper from 'swiper';

export default function Slideshow(container) {
    this.els = {
        container: container,
        wrapper: dom.get('.js-slideshow-slides', container)[0],
        slides: dom.get('.js-slideshow-slide', container),
        pips: dom.get('.js-slideshow-pips', container)[0],
    };

    if (this.els.slides.length < 2) {
        return;
    }

    this.addClasses();
    this.swiper = new Swiper(this.els.container, {
        autoplay: 5000,
        speed: 500,
        pagination: this.els.pips,
        paginationClickable: true,
        paginationBulletRender: (swiper, index, className) => {
            return `<button class="${className}"></button>`;
        },
        bulletClass: 'pip',
        bulletActiveClass: 'pip--active',
        onInit: swiper => {
            swiper.stopAutoplay();
            initVideos(swiper.wrapper[0]);
            initSlide(swiper.slides[swiper.realIndex], swiper);
        },
    });
    this.swiper.on('slideChangeStart', swiper => {
        initVideos(swiper.wrapper[0]);
    });
    this.swiper.on('slideChangeEnd', swiper => {
        initSlide(swiper.slides[swiper.realIndex], swiper);
    });

    for (let i = 0; i < this.swiper.slides.length; i++) {
        const videos = getVideos(this.swiper.slides[i]);

        if (videos.length > 0) {
            videos[0].addEventListener('ended', () => {
                this.swiper.slideNext();
            });
        }
    }
}

Slideshow.prototype.addClasses = function () {
    this.els.container.classList.add('swiper-container');
    this.els.wrapper.classList.add('swiper-wrapper');
    this.els.slides.forEach(slide => slide.classList.add('swiper-slide'));
};

Slideshow.init = function () {
    return dom.get('.js-slideshow').map(el => new Slideshow(el));
};

function initSlide(slide, swiper) {
    const videos = getVideos(slide);

    if (videos.length > 0) {
        swiper.stopAutoplay();
        videos.forEach(video => video.play());
    } else {
        swiper.startAutoplay();
    }
}

function getVideos(container) {
    return dom.get('video', container);
}

function initVideos(container) {
    getVideos(container).forEach(video => {
        video.pause();
        video.currentTime = 0;
        video.loop = false;
    });
}
