import dom from '../../lib/dom';
import Swiper from 'swiper';
import SwiperUtil from '../swiper-util';

export default class Guide {
    constructor(container) {
        if (null === container) {
            return;
        }

        this.container = SwiperUtil.createContainer(container, dom.get('.js-page', container));
        this.swiper = Guide.createSwiper(this.container);
        this.showNavigation();
    }

    showNavigation() {
        document.getElementById('guide-navigation').removeAttribute('hidden');
    }

    static createSwiper(container) {
        return new Swiper(container, {
            a11y: true,
            simulateTouch: false,
            hashnav: true,
            hashnavWatchState: true,
            prevButton: '.js-prev-button',
            nextButton: '.js-next-button',
            buttonDisabledClass: 'is-disabled',
        });
    }
}
