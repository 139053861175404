import dom from '../../lib/dom';

const hiddenClass = 'is-hidden';

export class RevealChildren {
    constructor(el) {
        this.el = el;
        this.children = dom.get(this.getChildSelector(), el);
        this.interval = this.getInterval();
        this.hide();
        this.waypoint = new Waypoint({
            element: el,
            offset: '50%',
            handler: this.reveal.bind(this),
        });
    }

    getChildSelector() {
        if (this.el.hasAttribute('data-child-selector')) {
            return this.el.getAttribute('data-child-selector');
        }

        return '.js-reveal-child';
    }

    getInterval() {
        return parseInt(this.el.getAttribute('data-interval') || 0, 10);
    }

    hide() {
        this.children.forEach(child => {
            child.classList.add(hiddenClass);
        });
        this.revealed = false;
    }

    reveal() {
        if (this.revealed) {
            return;
        }

        this.children.forEach((child, i) => {
            setTimeout(() => child.classList.remove(hiddenClass), i * this.interval);
        });
        this.revealed = true;
    }

    static initAll(selector = '.js-reveal-children') {
        return dom.get(selector).map(el => {
            return new RevealChildren(el);
        });
    }
}
