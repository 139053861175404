import Benefits from './benefits';
import TableOfContents from './toc';
import { RevealChildren } from './effects';
import CountUp from './countup';

export default function FishForLife() {
    Benefits.initAll();
    TableOfContents.initAll();
    RevealChildren.initAll();
    CountUp.initAll();
}
