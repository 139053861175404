import dom from './dom';

export default (() => {
    const submitButtonSelectors = [
        '[type="submit"]',
        'input[type="image"]',
        'button:not([type])',
        'button[type=""]'
    ];

    /**
     * Protects a form by disabling submit buttons until the form is valid; and
     * prevents double submissions.
     */
    const protect = form => {
        if (null === form) {
            return;
        }

    	if ('FORM' !== form.tagName) {
        	throw `Parameter must be a <form> element, ${form.tagName} given.`;
        }

        if (!('checkValidity' in form) || form.hasAttribute('novalidate')) {
        	return;
        }

        const submitButtons = dom.get(submitButtonSelectors.join(','), form);
        const disable = el => el.disabled = true;
        const enable = el => el.disabled = false;

        function enableButtonsIfValid() {
            submitButtons.forEach(form.checkValidity() ? enable : disable);
        }

        function disableButtonsIfValid() {
            if (form.checkValidity()) {
                submitButtons.forEach(disable);
            }
        }

        // Set the initial enabled/disabled state – wrapped in a `setTimeout`
        // call to avoid caching states from a previous page load.
        setTimeout(enableButtonsIfValid, 10);

        form.addEventListener('input', enableButtonsIfValid);
        form.addEventListener('change', enableButtonsIfValid);
        form.addEventListener('submit', disableButtonsIfValid);
    };

    return { protect };
})();
