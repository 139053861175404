'use strict';

import 'core-js/fn/object/entries';

import 'waypoints/lib/noframework.waypoints';

import A11yDialog from 'a11y-dialog';
import A11yTabs from './lib/a11y-tabs';
import { createPrevButton, createNextButton } from './src/carousel-buttons';
import CutsTheMustard from './lib/cutsthemustard';
import dom from './lib/dom';
import FileInput from './src/file-input';
import FishForLife from './src/fish-for-life/index';
import Floodlights from './src/floodlights';
import form from './lib/form';
import GastroDateNight from './src/gastro-date-night/index';
import HeightEqualiser from './lib/height-equaliser';
import imagesLoaded from 'imagesloaded';
import measureScrollbar from './lib/measure-scrollbar';
import { Carousel as MadeInBritainCarousel } from './src/made-in-britain';
import Menu from './src/menu';
import Messenger from './lib/messenger';
import ProductSlider from './src/product-slider';
import reqwest from 'reqwest';
import Search from './src/search';
import Slideshow from './src/slideshow';
import StarRating from './src/star-rating';
import { supports as supportsCSS } from './lib/css';
import 'svgxuse';
import Swiper from 'swiper';
import util from './lib/util';
import WordCount from './src/word-count';
import Youngsters from './src/youngsters/index';

// Feature detection
const ctm = new CutsTheMustard();

// Prevent page content jumping when dialog opens by measuring the scrollbar
// and changing padding.
const scrollbarWidth = measureScrollbar();

function showDialog() {
    document.body.classList.add('overlay-open');
    document.body.style.paddingRight = `${scrollbarWidth}px`;
}

function hideDialog() {
    document.body.classList.remove('overlay-open');
    document.body.style.paddingRight = '';
}

// Floodlight tags
Floodlights.init();

// Star rating fields
StarRating.initAll();

// Expose Messenger constructor to window object
ctm.pass(() => {
    window.Messenger = Messenger;

    if (window.initIframe) {
        window.initIframe();
    }
});

/**
 * Word count indicators
 */
ctm.pass(() => {
    WordCount.initAll();
});

/**
 * File inputs
 */
ctm.pass(() => {
    FileInput.initAll();
});

ctm.pass(() => {
    const youngstersTemplateClass = 'body--youngsters';

    if (document.body.classList.contains(youngstersTemplateClass)) {
        new Youngsters();
    }
});

/**
 * Gastro Date Night
 */
ctm.pass(() => {
    const gdnTemplateClass = 'body--page-template-gastro-date-night';

    if (document.body.classList.contains(gdnTemplateClass)) {
        new GastroDateNight();
    }
});

/**
 * Fish for Life
 */
ctm.pass(() => {
    const fflTemplateClass = 'body--fish-for-life';

    if (document.body.classList.contains(fflTemplateClass)) {
        new FishForLife();
    }
});

/**
 * Made in Britain
 */
ctm.pass(() => {
    new MadeInBritainCarousel();
});

/**
 * Back links
 */
dom.get('a.js-back').forEach(link => {
    link.addEventListener('click', event => {
        history.back();
        event.preventDefault();
    });
});

/**
 * Video background
 */
ctm.pass(() => {
    if (!supportsCSS('object-fit', 'cover')) {
        return;
    }

    dom.get('.js-video-background').forEach(el => {
        if (!el.hasAttribute('data-src')) {
            return;
        }

        if (el.hasAttribute('data-media')) {
            const mq = matchMedia(el.getAttribute('data-media'));

            if (!mq.matches) {
                return;
            }
        }

        const video = document.createElement('video');
        video.src = el.getAttribute('data-src');
        video.autoplay = true;
        video.controls = false;
        video.loop = true;
        video.className = el.getAttribute('data-class') || 'background-video';

        el.parentNode.replaceChild(video, el);
    });
});

/**
 * Slideshow
 */
ctm.pass(() => {
    Slideshow.init();
});

/**
 * Equalise heights
 */
ctm.pass(() => {
    dom.get('.js-equalise-us').map(container => {
        return new HeightEqualiser(container);
    });
});

/**
 * Extra items in range grid
 */
ctm.pass(() => {
    function getNextMultiple(x, y) {
        if (x % y === 0) {
            return x;
        }

        return parseInt(Math.ceil(x / y) * y, 10);
    }

    function howManyExtras(coreCount, columns) {
        return getNextMultiple(coreCount, columns) - coreCount;
    }

    function show(item) {
        item.removeAttribute('hidden');
    }

    function hide(item) {
        item.setAttribute('hidden', true);
    }

    const alpha = window.matchMedia('(min-width: 1025px)');
    const gamma = window.matchMedia('(min-width: 768px) and (max-width: 1024px)');
    const epsilon = window.matchMedia('(min-width: 500px) and (max-width: 767px)');

    dom.get('.js-fill-grid').forEach(grid => {
        const core = dom.get('.js-core-item', grid);
        const extras = dom.get('.js-extra-item', grid);

        if (core.length === 0) {
            return;
        }

        function showExtras() {
            extras.forEach(hide);

            if (alpha.matches) {
                extras.slice(0, howManyExtras(core.length, 4)).forEach(show);
            } else if (gamma.matches) {
                extras.slice(0, howManyExtras(core.length, 3)).forEach(show);
            } else if (epsilon.matches) {
                extras.slice(0, howManyExtras(core.length, 2)).forEach(show);
            }
        }

        window.addEventListener('resize', util.debounce(showExtras, 100));
        showExtras();

        const eq = new HeightEqualiser(grid);
        eq.events.on('resize', () => {
            extras.forEach(extra => extra.style.height = `${core[0].offsetHeight}px`);
        });
    });

});

/**
 * Share links
 */
ctm.pass(() => {
    dom.get('a.js-share-link').forEach(link => {
        link.addEventListener('click', event => {
            window.open(link.href, 'share', 'width=600,height=300,toolbar=no');
            event.preventDefault();
        });
    });
});

/**
 * Newsletter form
 */
ctm.pass(() => {
    const newsletterForm = document.getElementById('newsletter-form');

    if (null === newsletterForm) {
        return;
    }

    form.protect(newsletterForm);
});

/**
 * Show/hide lists
 */
ctm.pass(() => {
    dom.get('button.js-show-list').forEach(button => {
        const target = document.getElementById(button.getAttribute('data-target'));

        if (null === target) {
            return;
        }

        const media = button.getAttribute('data-media') || 'all';
        const mq = matchMedia(media);

        function hideIfMatches() {
            if (mq.matches) {
                target.setAttribute('hidden', true);
                button.setAttribute('aria-controls', target.id);
                button.addEventListener('click', () => {
                    target.removeAttribute('hidden');
                    button.parentNode.removeChild(button);
                });
            } else {
                target.removeAttribute('hidden');
                button.removeAttribute('aria-controls');
            }
        }

        mq.addListener(hideIfMatches);
        hideIfMatches();
    });
});

/**
 * Swapping recipe parts around
 */
ctm.pass(() => {
    const ingredients = document.getElementById('recipe-ingredients');
    const method = document.getElementById('recipe-method');
    const product = document.getElementById('recipe-product');
    const glossary = document.getElementById('recipe-glossary');

    if ([ingredients, method, product, glossary].some(el => el === null)) {
        return;
    }

    const mq = window.matchMedia('(min-width: 768px)');

    function reshuffle(mq) {
        if (mq.matches) {
            ingredients.appendChild(product);
            method.insertBefore(glossary, method.firstChild);
        } else {
            method.appendChild(product);
            ingredients.insertBefore(glossary, ingredients.firstChild);
        }
    }

    mq.addListener(reshuffle);
    reshuffle(mq);
});

/**
 * Menu
 */
ctm.pass(() => new Menu());

/**
 * Slim header on scroll
 */
ctm.pass(() => {
    const scrollThreshold = 125;
    const header = document.getElementById('header');
    const logo = document.getElementById('logo');
    const menu = document.getElementById('main-menu');

    function toggleHeader() {
        const scrollPos = window.pageYOffset || document.scrollTop || 0;

        if (scrollPos >= scrollThreshold) {
            header.classList.add('sticky-header--shift');
            logo.classList.add('header__logo--nudge');
            menu.classList.add('main-menu--split');
            menu.classList.add('header__main-menu--slim');
        } else {
            header.classList.remove('sticky-header--shift');
            logo.classList.remove('header__logo--nudge');
            menu.classList.remove('main-menu--split');
            menu.classList.remove('header__main-menu--slim');
        }
    }

    window.addEventListener('scroll', util.debounce(toggleHeader, 10));
    toggleHeader();
});

/**
 * Tabs
 */
ctm.pass(() => {
    const tabs = document.getElementById('tabs');

    if (null === tabs) {
        return;
    }

    new A11yTabs(tabs, 'tab--selected', window.matchMedia('(min-width: 900px)'));
});

/**
 * Stores dialog
 */
ctm.pass(() => {
    const overlay = document.getElementById('buy-overlay');

    if (null === overlay) {
        return;
    }

    const dialog = new A11yDialog(overlay);

    dialog.on('show', showDialog);
    dialog.on('hide', hideDialog);

    if (window.location.hash === '#retailers') {
        dialog.show();
    }

    // Allow the inclusion of “buy now” buttons for any product on any page,
    // by adding a `data-buy-now="[product url]"` attribute.
    const productUrlAttribute = 'data-buy-now';
    const content = document.getElementById('retailers-list');

    dom.get(`button[${productUrlAttribute}]`).forEach(button => {
        const productUrl = button.getAttribute(productUrlAttribute);
        const retailersUrl = productUrl.replace(/\/$/, '') + '/retailers/';

        button.addEventListener('click', () => {
            reqwest(retailersUrl, response => {
                content.innerHTML = response;
                dialog.show();
            });
        });
    });
});

/**
 * Search dialog
 */
ctm.pass(() => {
    const overlay = document.getElementById('search-overlay');
    const mq = window.matchMedia('(min-width: 768px)');

    if (null === overlay || !mq.matches) {
        return;
    }

    // Change search forms into buttons which open the dialog.
    dom.get('.js-search-form').forEach(form => {
        const input = dom.get('.js-search-input', form)[0];

        if (!input) {
            return;
        }

        const button = document.createElement('button');
        button.type = 'button';
        button.id = input.id;
        button.className = `${input.className}  search-form__input--button`;
        button.innerText = input.placeholder;
        button.setAttribute('data-a11y-dialog-show', overlay.id);

        form.parentNode.replaceChild(button, form);
    });

    const dialog = new A11yDialog(overlay);
    const search = new Search(dialog, window.ajaxUrl);

    // "Popular searches" links should open dialog and trigger the search in
    // one click.
    dom.get('a.js-search-link').forEach(link => {
        const query = link.textContent.trim();

        link.addEventListener('click', event => {
            dialog.show();
            search.els.input.value = query;
            search.handleInput();

            event.preventDefault();
        });
    });

    search.events.on('show', showDialog);
    search.events.on('hide', hideDialog);

    // Equalise heights of search results on load.
    const equaliser = new HeightEqualiser(search.els.results, false);

    search.events.on('results_loaded', () => {
        imagesLoaded(search.els.results, () => {
            equaliser.updateItems();
            equaliser.equalise();
        });
    });

    /*
    search.events.on('results', query => {
        window.history.pushState({ query }, '', `/?s=${encodeURIComponent(query)}`);
    });

    search.events.on('empty', () => {
        window.history.pushState({ query: '' }, '', '/');
    });

    window.addEventListener('popstate', event => {
        if (event.state) {
            if (event.state.query === undefined) {
                return;
            }

            if (event.state.query === '') {
                // close dialog
            }

            // open dialog + search
        }
    });
    */
});

/**
 * Carousels
 */
ctm.pass(() => {
    const carousels = dom.get('.js-carousel');

    if (carousels.length === 0) {
        return;
    }

    function itemToSlide(item) {
        const slide = document.createElement('div');
        slide.className = 'swiper-slide  carousel__slide';
        slide.appendChild(item);

        return slide;
    }

    function createContainer() {
        const container = document.createElement('div');
        container.className = 'carousel  swiper-container';
        container.appendChild(createPrevButton('carousel__button'));
        container.appendChild(createNextButton('carousel__button'));

        return container;
    }

    const swiperOptions = {
        a11y: true,
        spaceBetween: 15,
        slidesPerView: 3,
        loop: true,
        loopAdditionalSlides: 3,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        speed: 500,
        prevButton: '.js-prev',
        nextButton: '.js-next',
        buttonDisabledClass: 'arrow--disabled',
        breakpoints: {
            860: { slidesPerView: 2 },
            550: { slidesPerView: 1 },
        },
        onInit: swiper => {
            new HeightEqualiser(swiper.container[0]);
        },
    };

    carousels.map(carousel => {
        const wrapper = document.createElement('div');
        wrapper.className = 'swiper-wrapper';

        dom.get('.js-carousel-item', carousel).forEach(item => {
            wrapper.appendChild(itemToSlide(item));
        });

        const container = createContainer();
        container.appendChild(wrapper);

        carousel.parentNode.replaceChild(container, carousel);

        return new Swiper(container, swiperOptions);
    });
});

ctm.pass(() => {
    const productPack = document.getElementById('product-pack');

    if (null === productPack || !productPack.hasAttribute('data-product-id')) {
        return;
    }

    const productId = parseInt(productPack.getAttribute('data-product-id'), 10);
    const rangeId = parseInt(productPack.getAttribute('data-range-id'), 10);

    reqwest({
        url: window.ajaxUrl,
        data: {
            action: 'range_product_info',
            range: rangeId,
        },
        type: 'json',
        success: response => {
            new ProductSlider(productPack, response.keys, response.products, productId);
        },
    });
});
