import dom from '../lib/dom';
import { createPrevButton, createNextButton } from './carousel-buttons';
import Swiper from 'swiper';

export function Carousel() {
    this.containers = dom.get('.js-mib-carousel');
    this.carousels = this.containers.map(container => {
        const wrapper = dom.get('.js-mib-carousel-slides', container)[0];
        const slides = dom.get('.js-mib-carousel-slide', container);

        container.classList.add('swiper-container');
        wrapper.classList.add('swiper-wrapper');

        slides.forEach((slide, i, slides) => {
            slide.classList.add('swiper-slide');

            dom.get('.js-mib-carousel-pips', slide).forEach(container => {
                container.appendChild(createPips(slides.length, i));
            });
        });

        container.appendChild(createPrevButton('mib-carousel__button'));
        container.appendChild(createNextButton('mib-carousel__button'));

        const swiper = new Swiper(container, {
            a11y: true,
            loop: true,
            prevButton: '.js-prev',
            nextButton: '.js-next',
        });

        return swiper;
    });
}

function createPips(count, activeIndex) {
    const fragment = document.createDocumentFragment();

    for (let i = 0; i < count; i++) {
        fragment.appendChild(createPip(i === activeIndex));
    }

    return fragment;
}

function createPip(active) {
    const pip = document.createElement('span');
    pip.classList.add('mib-pip');

    if (active) {
        pip.classList.add('mib-pip--active');
    }

    return pip;
}
