export default class Floodlights {
    constructor(simulated = false) {
        this.simulated = simulated;

        if (this.isActive()) {
            this.sendGroup(document.body.getAttribute('data-floodlight'));
        }
    }

    sendGroup(key) {
        if (Floodlights.tags[key] === undefined) {
            return;
        }

        Object.entries(Floodlights.tags[key]).forEach(([ tag, timeout ]) => {
            window.setTimeout(this.send.bind(this, tag), timeout * 1000);
        });
    }

    send(tag) {
        const trackingCode = `DC-8939132/young0/${tag}`;

        if (this.simulated) {
            console.log(`Tracking event ${trackingCode}`);

            return;
        }

        window.gtag('event', 'conversion', {
            allow_custom_scripts: true,
            send_to: trackingCode,
        });
    }

    isActive() {
        const hasAttr = document.body.hasAttribute('data-floodlight');

        if (this.simulated) {
            return hasAttr;
        }

        return hasAttr && typeof window.gtag !== 'undefined';
    }

    static init(simulated = false) {
        return new Floodlights(simulated);
    }
}

Floodlights.tags = {
    'range.gastro': {
        'young00e+standard': 0,
        'young00d+standard': 15,
        'young00c+standard': 30,
    },
    'range.chip-shop': {
        'young00-+standard': 0,
        'young00a+standard': 15,
        'young00b+standard': 30,
    },
};
